import React, { Component } from 'react';
import Layout from '../../../components/Layout';
import PageHeading from '../../../components/PageHeading/PageHeading';

class CurriculaSearch extends Component {
  render() {
    return (
      <Layout>
        <PageHeading>Thieu Nhi Curriculum</PageHeading>
        <h1>TN curricula</h1>
      </Layout>
    );
  }
}

export default CurriculaSearch;
